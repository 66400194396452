import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import { RouterTabRoutes } from 'vue-router-tab';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
NProgress.configure({showSpinner: false});

Vue.use(Router);
const originalPush = Router.prototype.push;
// TODO:
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(() => {});
};

// 重写了VueRouter的go和back方法，在router返回时关闭当前页签
Router.prototype.go = Router.prototype.back = function go () {
  let items = Vue.prototype.$tabs?.items;
  let activeItemId = Vue.prototype.$tabs?.activeTabId;
  let activeItemIndex = items.findIndex((t) => t.id === activeItemId);
  if (activeItemIndex > 0) {
    Vue.prototype.$tabs.close({ id: activeItemId, to: items[activeItemIndex - 1].to });
  } else {
    if (items.length === 1) {
      Vue.prototype.$tabs.close({ id: activeItemId, to: store.getters.firstUrl });
    } else {
      Vue.prototype.$tabs.close();
    }
  }
};

// 重新replace方法，使之匹配vue-routeTab
const originReplace = Router.prototype.replace;
Router.prototype.replace = function replace () {
  let activeTabId = Vue.prototype.$tabs?.activeTabId;
  originReplace.apply(this, arguments).then(() => {
    activeTabId && Vue.prototype.$tabs.close({ id: activeTabId });
  });
};

const routes = [
  {
    path: '/login',
    name: 'LOGIN',
    // component: () => import('@/views/Login.vue'),
    component: () => import('@/views/LoginRegister.vue'),
  },
  {
    path: '/',
    name: 'HOME',
    component: () => import('@/views/Layout/index.vue'),
    redirect: { name: 'WORKBENCH' },
    children: [
      ...RouterTabRoutes,
      {
        path: 'userInfo',
        name: 'USER_INFO',
        component: () => import('@/views/UserInfo.vue'),
        meta: {
          title: '账号安全',
        },
      },
      {
        path: 'userDrawer',
        name: 'USER_DRAWER',
        component: () => import('@/views/UserDrawer/index.vue'),
        meta: {
          title: '个人资料',
        },
      },
      {
        path: 'order/list',
        name: 'ORDER_INDEX',
        component: () => import('@/views/Order/index.vue'),
        meta: {
          title: '工单列表',
        },
      },
      {
        path: 'order/collaborativeTicketsList',
        name: 'ORDER_COLLABORATIVETICKETSLIST',
        component: () => import('@/views/Order/index.vue'),
        props: () => ({ isSynergy: true }),
        meta: {
          title: '协同工单列表',
        },
      },

      {
        path: 'workbench',
        name: 'WORKBENCH',
        component: () => import('@/views/Workbench/index.vue'),
        meta: {
          title: '服务大厅',
          closable: false,
        },
      },
      {
        path: 'workbench/identification',
        name: 'IDENTIFICATION',
        component: () => import('@/views/Workbench/identification.vue'),
        meta: {
          title: '企业认证',
        },
      },
      {
        path: 'workbench/messages',
        name: 'MESSAGES',
        component: () => import('@/views/Workbench/messages.vue'),
        meta: {
          title: '消息中心',
        },
      },
      {
        path: 'join/xiaozi',
        name: 'JOIN_XIAOZI',
        component: () => import('@/views/Join/xiaozi.vue'),
        meta: {
          title: '加盟服务',
        },
      },
      {
        path: 'join/yixiubao',
        name: 'JOIN_YIXIUBAO',
        component: () => import('@/views/Join/yixiubao.vue'),
        meta: {
          title: '医修保',
        },
      },
      {
        path: 'orderWall',
        name: 'ORDERWALL',
        component: () => import('@/views/OrderWall/index.vue'),
        meta: {
          title: '医修保首页',
        },
      },
      {
        path: 'orderWall/synergism',
        name: 'ORDERWALL_SYNERGISM',
        component: () => import('@/views/OrderWall/synergism.vue'),
        meta: {
          title: '全部待办',
        },
      },
      {
        path: 'order/add',
        name: 'ORDER_ADD',
        component: () => import('@/views/Order/edit.vue'),
        props: route => ({ ...route.query }),
        meta: {
          title: '新增工单',
        },
      },
      {
        path: 'order/edit',
        name: 'ORDER_EDIT',
        component: () => import('@/views/Order/edit.vue'),
        props: route => ({ ...route.query }),
        meta: {
          title: '编辑工单',
        },
      },
      {
        path: 'external/order/edit',
        name: 'EXTERNAL_ORDER_EDIT',
        component: () => import('@/views/Order/edit.vue'),
        props: route => ({ ...route.query, isSynergy: true }),
        meta: {
          title: '协同工单编辑',
          key: 'fullPath',
        },
      },
      {
        path: '/import/:type',
        name: 'ORDER_IMPORT',
        component: () => import('@/views/Order/import.vue'),
        props: route => ({ ...route.params }),
        meta: {
          title: '批量新增',
          key: 'path',
        },
      },
      {
        path: 'customer/list',
        name: 'CUSTOMER_INDEX',
        component: () => import('@/views/Customer/index.vue'),
        meta: {
          title: '医院档案',
        },
      },
      {
        path: 'customer/add',
        name: 'CUSTOMER_ADD',
        component: () => import('@/views/Customer/detail.vue'),
        meta: {
          title: '新增医院',
        },
      },
      {
        path: 'customer/detail',
        name: 'CUSTOMER_DETAIL',
        component: () => import('@/views/Customer/detail.vue'),
        meta: {
          title: '医院档案详情',
        },
      },
      {
        path: 'contact/list',
        name: 'CONTACT_INDEX',
        component: () => import('@/views/Contact/index.vue'),
        meta: {
          title: '医院联系人',
        },
      },
      {
        path: 'contact/add',
        name: 'CONTACT_ADD',
        component: () => import('@/views/Contact/detail.vue'),
        meta: {
          title: '新增联系人',
        },
      },
      {
        path: 'contact/detail',
        name: 'CONTACT_DETAIL',
        component: () => import('@/views/Contact/detail.vue'),
        meta: {
          title: '医院联系人详情',
        },
      },
      {
        path: 'accounts/list',
        name: 'ACCOUNTS_INDEX',
        component: () => import('@/views/Accounts/index.vue'),
        meta: {
          title: '医工端账套',
        },
      },
      {
        path: '/systemSettings/userInfo',
        name: 'UserInfo',
        component: () => import('@/views/SystemSettings/userInfo/userInfo.vue'),
        meta: {
          title: '医工端设置',
        },
      },
      {
        path: '/systemSetting/account/list',
        name: 'Account',
        component: () => import('@/views/SystemSettings/account/index.vue'),
        meta: {
          title: '云市场订单',
        },
      },
      {
        path: '/systemSetting/department/list',
        name: 'Department',
        component: () => import('@/views/SystemSettings/department/index.vue'),
        meta: {
          title: '部门管理',
        },
      },
      {
        path: '/systemSetting/employee/list',
        name: 'Employee',
        component: () => import('@/views/SystemSettings/employee/index.vue'),
        meta: {
          title: '成员管理',
        },
      },
      {
        path: '/systemSetting/employee/detail',
        name: 'EmployeeDetail',
        component: () => import('@/views/SystemSettings/employee/detail.vue'),
        meta: {
          title: '员工详情',
        },
        props: route => ({
          id: route.query.id,
        }),
      },
      {
        path: '/systemSetting/corporate/certification',
        name: 'Company',
        component: () => import('@/views/SystemSettings/company/index.vue'),
        meta: {
          title: '企业认证',
        },
      },
      {
        path: '/systemSetting/company/profile',
        name: 'CompanyProfile',
        component: () => import('@/views/SystemSettings/company/profile.vue'),
        meta: {
          title: '企业档案',
        },
      },
      {
        path: '/systemSetting/company/detail',
        name: 'CompanyDetail',
        component: () => import('@/views/SystemSettings/company/detail.vue'),
        meta: {
          title: '加盟服务-详情',
        },
      },
      {
        path: '/systemSetting/corporate/certification/edit',
        name: 'CorporateCertification',
        component: () => import('@/views/SystemSettings/company/corporateCertification.vue'),
        meta: {
          title: '加盟服务-编辑',
        },
      },
      {
        path: '/systemSetting/portal/home',
        name: 'PortalHome',
        component: () => import('@/views/SystemSettings/company/PortalHome.vue'),
        meta: {
          title: '云产品中心',
        },
      },
      {
        path: '/systemSetting/employee/edit',
        name: 'EmployeeEdit',
        component: () => import('@/views/SystemSettings/employee/edit.vue'),
        meta: {
          title: '编辑员工',
        },
        props: route => ({
          id: route.query.id,
        }),
      },
      {
        path: '/systemSetting/role/list',
        name: 'Role',
        component: () => import('@/views/SystemSettings/role/index.vue'),
        meta: {
          title: '角色权限',
        },
      },
      {
        path: '/system/sparePartWarehouseSet',
        name: 'system_sparePartWarehouseSet',
        component: () => import('@/views/SystemSettings/stock'),
        meta: {
          title: '备件仓储设置',
        },
      },
      {
        path: '/feedback',
        name: 'FeedbackLists',
        component: () => import('@/views/Feedback/feedbackLists.vue'),
        meta: {
          title: '意见反馈',
        },
      },
      {
        path: '/feedback/add',
        name: 'AddFeedbackLists',
        component: () => import('@/views/Feedback/feedbackInfo.vue'),
        meta: {
          title: '新增意见反馈',
        },
      },
      {
        path: '/feedback/edit',
        name: 'EditFeedbackLists',
        component: () => import('@/views/Feedback/feedbackInfo.vue'),
        meta: {
          title: '编辑意见反馈',
        },
      },
      {
        path: '/feedback/view',
        name: 'ViewFeedbackLists',
        component: () => import('@/views/Feedback/feedbackInfo.vue'),
        meta: {
          title: '查看意见反馈',
        },
      },
      {
        path: '/sparePart/list',
        name: 'SPAREPART_LIST',
        component: () => import('@/views/StockIn/list'),
        meta: {
          title: '备件列表',
        },
      },
      {
        path: '/sparePart/sparePartType',
        name: 'SPAREPART_TYPE',
        component: () => import('@/views/StockIn/sparePartType'),
        meta: {
          title: '备件类型',
        },
      },
      {
        path: '/sparePart/list/detail',
        name: 'SPAREPART_DETAIL',
        component: () => import('@/views/StockIn/list/detail'),
        meta: {
          title: '备件详情',
        },
      },
      {
        path: '/sparePart/stock',
        name: 'SPAREPART_STOCK',
        component: () => import('@/views/StockIn/inventory'),
        meta: {
          title: '备件库存',
        },
      },
      {
        path: '/sparePart/stockInAndOutRecord',
        name: 'SPAREPART_STOCKINANDOUTRECORD',
        component: () => import('@/views/StockIn/record'),
        meta: {
          title: '出入库记录',
        },
      },
      {
        path: '/sparePart/handleStockInAndOut',
        name: 'SPAREPART_HANDLESTOCKINANDOUT',
        component: () => import('@/views/StockIn/deal'),
        meta: {
          title: '办理出入库',
        },
      },
      {
        path: 'sparePart/personalWarehouse',
        name: 'SPAREPART_PERSONALWAREHOUSE',
        component: () => import('@/views/StockIn/personal'),
        meta: {
          title: '个人备件库',
        },
      },
      {
        path: 'sparePart/stock/manager',
        name: 'SPAREPART_STOCK_MANAGER',
        component: () => import('@/views/StockIn/inventory/stockManager'),
        meta: {
          title: '管理仓库',
        },
      },
      {
        path: 'sparePart/stock/type/manager',
        name: 'SPAREPART_STOCK_TYPE_MANAGER',
        component: () => import('@/views/StockIn/inventory/stockType'),
        meta: {
          title: '管理仓库类型',
        },
      },
      {
        path: 'sparePart/handleStockInAndOut/detail',
        name: 'sparePart_handleStockInAndOut_detail',
        component: () => import('@/views/StockIn/deal/detail'),
        props: route => ({ id: route.query.id }),
        meta: {
          title: '办理出入库详情',
        },
      },
      {
        path: 'operation/report',
        name: 'OPERATION_REPORT',
        component: () => import('@/views/Operation/report/index'),
        meta: {
          title: '统计报表',
        },
      },
      {
        path: 'operation/report/detail',
        name: 'OPERATION_REPORT_DETAIL',
        component: () => import('@/views/Operation/report/detail'),
        meta: {
          title: '统计报表详情',
        },
        props: route => ({ ...route.query }),
      },
      {
        path: 'form/config',
        name: 'FORM_INDEX',
        component: () => import('@/views/Form/index'),
        meta: {
          title: '医搭',
        },
      },
      {
        path: 'form/setting',
        name: 'FORM_SETTING',
        component: () => import('@/views/Form/setting/form'),
        props: route => ({ ...route.query }),
        meta: {
          title: '自定义表单',
        },
      },
      {
        path: 'form/print/template',
        name: 'FORM_PRINT_TEMPLATE',
        component: () => import('@/views/Form/printTemplate/list'),
        props: route => ({ ...route.query }),
        meta: {
          title: '打印配置',
        },
      },
      {
        path: 'form/print/template/:action',
        name: 'FORM_PRINT_TEMPLATE_DETAIL',
        component: () => import('@/views/Form/printTemplate/detail'),
        props: route => ({ ...route.query }),
        meta: {
          title: '打印模板设置',
        },
      },
      {
        path: 'form/setting/component',
        name: 'FORM_SETTING_COMPONENT',
        component: () => import('@/views/Form/setting/component'),
        props: route => ({ ...route.query }),
        meta: {
          title: '自定义业务组件',
        },
      },
      {
        path: 'customForm/list/:configCode',
        name: 'CUSTOMFORM_LIST',
        component: () => import('@/components/commonForm'),
        props: route => ({ ...route.params }),
        meta: {
          title: '自定义列表',
          key: 'path',
        },
      },
      {
        path: 'customForm/add/:configCode',
        name: 'CUSTOMFORM_add',
        component: () => import('@/components/commonForm/edit.vue'),
        props: route => ({ ...route.params, ...route.query }),
        meta: {
          title: '自定义列表新增',
          key: 'path',
        },
      },
      {
        path: 'customForm/edit/:configCode',
        name: 'CUSTOMFORM_edit',
        component: () => import('@/components/commonForm/edit.vue'),
        props: route => ({ ...route.params, ...route.query }),
        meta: {
          title: '自定义列表编辑',
          key: 'path',
        },
      },
      {
        path: 'customForm/detail/:configCode',
        name: 'CUSTOMFORM_detail',
        component: () => import('@/components/commonForm/edit.vue'),
        props: route => ({ ...route.params, ...route.query }),
        meta: {
          title: '自定义列表详情',
          key: 'path',
        },
      },
      {
        path: 'salesmanagement/salescontracts/list',
        name: 'SALESMANAGEMENT_SALESCONTRACTS_LIST',
        component: () => import('@/views/SalesManagement/saleContracts/list.vue'),
        meta: {
          title: '销售合同',
        },
      },
      {
        path: 'salesmanagement/salescontracts/new',
        name: 'SALESMANAGEMENT_SALESCONTRACTS_NEW',
        component: () => import('@/views/SalesManagement/saleContracts/newSaleContract.vue'),
        props: route => ({...route.query}),
        meta: {
          title: '新增销售合同',
        },
      },
      {
        path: 'salesmanagement/salescontracts/detail',
        name: 'SALESMANAGEMENT_SALESCONTRACTS_DETAIL',
        component: () => import('@/views/SalesManagement/saleContracts/detail.vue'),
        props: route => ({...route.query}),
        meta: {
          title: '销售合同详情',
        },
      },
      {
        path: 'salesmanagement/salescontracts/purchDetail',
        name: 'SALESMANAGEMENT_PURCHCONTRACTS_DETAIL',
        component: () => import('@/views/SalesManagement/saleContracts/purchDetail.vue'),
        props: route => ({...route.query}),
        meta: {
          title: '采购合同详情',
        },
      },
      {
        path: 'salesmanagement/billingOrder/list',
        name: 'SALESMANAGEMENT_BILLINGORDER_LIST',
        component: () => import('@/views/SalesManagement/billingOrder/list.vue'),
        props: route => ({...route.query}),
        meta: {
          title: '销售订单',
        },
      },
      {
        path: 'salesmanagement/billingOrder/new',
        name: 'SALESMANAGEMENT_BILLINGORDER_NEW',
        component: () => import('@/views/SalesManagement/billingOrder/newSaleOrder.vue'),
        props: route => ({...route.query}),
        meta: {
          title: '销售开单',
        },
      },
      {
        path: 'salesmanagement/billingOrder/detail',
        name: 'SALESMANAGEMENT_BILLINGORDER_SALE_DETAIL',
        component: () => import('@/views/SalesManagement/billingOrder/saleOrderDetail.vue'),
        props: route => ({...route.query}),
        meta: {
          title: '订单详情',
        },
      },
      {
        path: 'salesmanagement/billingOrder/purchDetail',
        name: 'SALESMANAGEMENT_BILLINGORDER_PURCH_DETAIL',
        component: () => import('@/views/SalesManagement/billingOrder/purchOrderDetail.vue'),
        props: route => ({...route.query}),
        meta: {
          title: '订单详情',
        },
      },
      {
        path: '/404',
        name: 'NOTFOUND',
        component: ()=>import('@/views/NotFound'),
        meta: {
          title: '错误页',
        },
      },
    ],
    meta: {
      title: '首页',
    },
  },
];

const router = new Router({
  routes,
});

router.beforeEach((to, from, next) => {
  let isLogin = !!localStorage.getItem('userInfo') && !!localStorage.getItem('token');
  if (to.name === 'LOGIN') {
    if (isLogin) {
      next({
        name: 'HOME',
      });
      return;
    } else {
      NProgress.start();
      next();
      return;
    }
  }
  if (!isLogin) {
    next({
      name: 'LOGIN',
      query: {
        redirect: to.fullPath,
      },
    });
    return;
  }

  if (!to.matched.length) {
    next({
      name: 'NOTFOUND',
    });
    return;
  }
  if (to.path === from.path) return;
  const { title, src } = to.params;
  if (to.path.indexOf('/iframe') >= 0 && src && src.indexOf('http') >= 0) {
    document.title = title || '小紫医疗';
  } else {
    document.title = to.meta.title || '小紫医疗';
  }
  NProgress.start();
  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
