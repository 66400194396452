import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/antd';
import './plugins/element';
import './plugins/vxe-table';
import './plugins/router-tab';

import Util from '@/utils/util';
import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js';
import '@/assets/iconfont-2/iconfont.css';
import '@/assets/iconfont-2/iconfont.js';
import '@/assets/style/index.scss';
import XEUtils from 'xe-utils';
import zhCN from 'vxe-table/lib/locale/lang/zh-CN';
import { VXETable, Header, Footer, Column, Colgroup, Toolbar, Radio, Grid, Pager, Table, Button, Select, Option, Tooltip, Edit, Switch, Validator, Input, Icon } from 'vxe-table';
import 'vxe-table/lib/style.css';
import filters from '@/filters';
import directives from './directives';

import { encode, decode } from './utils/coding.js';

import printjs from 'print-js';
import 'print-js/dist/print.css';

import VueDraggableResizable from 'vue-draggable-resizable';
VXETable.setup({
  zIndex: 8888,
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
});
Vue.use(VXETable).use(Header).use(Footer).use(Column).use(Colgroup).use(Toolbar).use(Radio).use(Grid).use(Pager).use(Edit).use(Validator).use(Button).use(Select).use(Option).use(Switch).use(Input).use(Tooltip).use(Table).use(Icon);
Vue.prototype.$encode = encode;
Vue.prototype.$decode = decode;

Vue.prototype.$print = printjs;

Vue.prototype.sourceUrl = 'https://api.yw.1xie.com/';

Vue.config.productionTip = false;
Vue.use(Util);
Vue.use(filters);
Vue.use(directives);
Vue.component('vue-draggable-resizable', VueDraggableResizable);

Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
